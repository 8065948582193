<template>
<Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <PageHead :title="title" :head="head" />
      <Body>
        <div class="flex h-screen overflow-hidden font-inter">
          <slot name="sidebar">
            <NavigationWhiteSidebar/>
          </slot>
          <div class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden min-h-max">
            <slot name="header">
            </slot>
            <main class="grow">
              <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-7xl mx-auto">
                <slot>
                </slot>
              </div>
            </main>
            <slot name="footer">
            </slot>
          </div>
        </div>
      </Body>
  </Html>
</template>

<script setup>
  const route = useRoute()
  const { t } = useI18n();

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
  })
  const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))
</script>

<style scoped>

</style>